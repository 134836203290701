import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { http } from "../../../_helpers";

export const AdminSupportResNew = () => {
  const [loading, setLoading] = useState(false);

  const { state } = useLocation();
  const [data, setData] = useState<any>(state);

  const navigate = useNavigate();

  const onChange = (key: any, value: any) => {
    setData({ ...data, [key]: value });
  };

  const upload = async (file: any, id: any) => {
    if (file) {
      console.log(file);
      var formData = new FormData();
      formData.append("file", file);
      const res = await http.post(
        encodeURI(`/chat/support/responses/${id}/attachments`),
        formData,
        "multipart/form-data; charset=utf-8; boundary=" +
          Math.random().toString().split(".")[1]
      );
      console.log(res);
    }
  };
  const onSubmit = async () => {
    setLoading(true);
    console.log("DATAAAAA",data);
    const res = await http.post(encodeURI(`/chat/support/responses`), {
      ...data,
      admin: "true",
    });
    if (res.code === 200) {
      if (Number(res.data) > 0) {
        await upload(data.file1, Number(res.data));
        await upload(data.file2, Number(res.data));
        await upload(data.file3, Number(res.data));
      }
      navigate(-1);
    } else {
      alert("Une erreur est survenue ! Merci de reéssayer.");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!state) {
      navigate("/admin/supports");
    }
  }, []);
  if (!state) {
    return <div />;
  }
  return (
    <div>
      <h4 className="text-muted mb-4">Support clients </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title d-flex justify-content-between mb-1 p-3">
              {!loading && <h5>Ajouter une reponse</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <textarea
                          rows={5}
                          className="form-control rounded-0"
                          id="message"
                          placeholder="Entrez votre message"
                          value={data.message}
                          onChange={(e) => onChange("message", e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="last_name">Fichier joint 1</label>
                        <input
                          type="file"
                          className="form-control rounded-0"
                          id="file1"
                          onChange={(e) =>
                            onChange("file1", e.target.files?.[0])
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="last_name">Fichier joint 2</label>
                        <input
                          type="file"
                          className="form-control rounded-0"
                          id="file2"
                          onChange={(e) =>
                            onChange("file1", e.target.files?.[0])
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label htmlFor="last_name">Fichier joint 3</label>
                        <input
                          type="file"
                          className="form-control rounded-0"
                          id="file3"
                          onChange={(e) =>
                            onChange("file1", e.target.files?.[0])
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={() => onSubmit()}
                    className="btn btn-lightning rounded-0 mb-2 mr-2"
                  >
                    Enregistrer
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn btn-outline-lightning rounded-0 mb-2"
                  >
                    Retour
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
