import { appConstants } from "../_constants";

const checkEnv = (env: string) => {
  return appConstants.ENV === env;
};

const isDev = () => {
  return checkEnv("dev");
};

const toString = (data: any) => {
  return typeof data === "string" ? data : JSON.stringify(data);
};

const string = (data: any) => {
  if (data) {
    return data;
  }
  return "-";
};

const goTo = (url: string) => {
  window.location.assign(url);
};

const transformRequest = (jsonData: any = {}) =>
  Object.entries(jsonData)
    .map((x: any) => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
    .join("&");

const formatDouble = (val: any) => {
  let v = val || 0;
  try {
    v = parseFloat(val.toString());
  } catch (error) {
    v = 0;
  }

  if (isNaN(v)) {
    v = 0;
  }
  return `${v
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
};

const formatInt = (val: any) => {
  let v = val || 0;
  try {
    v = parseInt(val.toString());
  } catch (error) {
    v = 0;
  }

  if (isNaN(v)) {
    v = 0;
  }
  return `${v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
};

const formatAmount = (curr: string, val: any) => {
  return `${formatDouble(val)} ${curr}`;
};
const isError = (level: string) => {
  return level === 'error';
};

const isWarm = (level: string) => {
  return level === 'warm';
};
const isInfo = (level: string) => {
  return level === 'info';
};
const log = (data: any, level = 'error') => {
  if (isDev()) {
    if (isError(level)) {
      console.error(toString(data));
    } else if (isWarm(level)) {
      console.warn(toString(data));
    } else if (isInfo(level)) {
      console.info(toString(data));
    } else {
      console.log(toString(data));
    }
  }
};

export const util = {
  checkEnv,
  isDev,
  toString,
  transformRequest,
  goTo,
  string,
  formatAmount,
  formatInt,
  formatDouble,
  log,
};
