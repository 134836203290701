import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { http, util } from "../../../_helpers";
import { appConstants } from "../../../_constants";
const { string } = util;

export * from "./show";

declare var $: any;
let fetching = false;
let tab: any = null;
export const AdminPayments = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<any[]>([]);

  const getItems = () => {
    if (fetching) return;
    fetching = true;
    http.get("/payments/activities/list?last5=true?admin=true").then((res) => {
      if (Array.isArray(res?.data)) {
        setItems(res.data);
        setTimeout(() => {
          if (($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
            if (tab) {
              tab.clear();
              tab.destroy();
            }
            $(ref.current).empty();
          }
          tab = ($(ref.current) as any).DataTable(
            appConstants.dataTableSettings
          );
        }, 400);
      }
      setLoading(false);
      fetching = false;
    });
  };

  const onShow = (state: any) => {
    navigate("show", { state });
  };

  const onDelete = async (state: any) => {
    let v = window.confirm(
      ` Attention vous êtes sur le point de supprimer le paiement ${state.orderId}. Cette action est irreversible !`
    );
    if (v) {
      setLoading(true);
      await http.remove(encodeURI(`/payments/remove/${state.orderId}`));
      getItems();
    }
  };
  useEffect(() => {
    getItems();
  }, []);
  return (
    <div>
      <h4 className="text-muted mb-4">Gestion des paiements </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Liste des paiements</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <div className="table-responsive-md">
                  <table
                    id="admin_table"
                    ref={ref}
                    className="table table-hover"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Lien</th>
                        <th scope="col">Type</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Client Name</th>
                        <th scope="col">Phone</th> 
                        <th scope="col">Activity Name</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>
                              {item.status !== "EXPIRED" && (
                                <a
                                  href={string(item.pay_url)}
                                  target={"_blank"}
                                >
                                  Afficher
                                </a>
                              )}
                              {item.status === "EXPIRED" && <b>-</b>}
                            </td>
                            <td>{string(item.payment_type)}</td>
                            <td>
                              {string(item.amount)} {string(item.currency_code)}
                            </td>
                            <td>{string(item.company_name)}</td>
                            <td className="text-center">
                              <td>{string(item.status)}</td>
                            </td>
                            <td>{string(item.client_name)}</td>
                            <td>{string(item.phone_number)}</td>
                            <td>{string(item.activity_name)}</td>


                            <td>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0 mr-2 "
                                onClick={() => onShow(item)}
                              >
                                <i className="fa fa-eye"></i>
                              </a>
                              {['INITIATED', 'FAILED', 'EXPIRED'].includes(item.status) && item.payment_type === "ACTIVITY" && (
                                <a
                                  className="btn btn-sm btn-outline-lightning rounded-0"
                                  onClick={() => onDelete(item)}
                                >
                                  <i className="far fa-trash-alt"></i>
                                </a>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
