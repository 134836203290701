import { util } from "../../_helpers";

export function SidebarProfile(props: any) {
  const doLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    util.goTo("/auth/login");
  };

  return (
    <div className="sidebar-profile d-flex flex-column justify-content-center pt-5 pb-3">
      <div className="picture-wrapper mb-4">
        <div className="user-picture m-auto">
          <img
            src="https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=Admin"
            className="img-fluid"
            alt=""
          />
        </div>
      </div>
      <div className="profile-details text-center pb-4">
        <small
          className="text-underline d-inline-block deconnexion-btn"
          onClick={doLogout}
        >
          Déconnexion
        </small>
      </div>
    </div>
  );
}
