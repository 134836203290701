import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { appConstants } from "../_constants";
import {
  AdminActivities,
  AdminActivitiesEdit,
  AdminActivitiesShow,
  AdminCompanies,
  AdminCompaniesEdit,
  AdminCompaniesShow,
  AdminDashboard,
  AdminUsers,
  AdminUsersEdit,
  AdminUsersShow,
  AdminAdministrateurs,
  AdminAdministrateursEdit,
  AdminAdministrateursShow,
  AdminPayments,
  AdminPaymentsShow,
  AdminLogs,
  AdminLogsShow,
  AdminSupports,
  AdminSupportsShow,
  AdminSupportsResShow,
  AdminSupportResNew,
  AdminSettingsEdit,
  AdminSettingsShow,
  AdminSettings,
  AdminOtpCodes,
  AdminOtpCodesShow,
  AdminActivitiesNew,
  AdminSubscriptions,
  AdminSubscriptionsShow,
  AdminSubscriptionsEdit,

  
} from "../_components";
const { JWT_TOKEN_KEY } = appConstants;

const AuthPage = lazy(() => import("../pages/Auth"));
const HomePage = lazy(() => import("../pages/Home"));
const AdminPage = lazy(() => import("../pages/Admin"));
const LoginPage = lazy(() => import("../pages/Login"));

function App() {
  const token =
    localStorage.getItem(JWT_TOKEN_KEY) ||
    sessionStorage.getItem(JWT_TOKEN_KEY);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route element={<PrivateRoute user={token} />}>
            <Route path="/admin" element={<AdminPage />}>
              <Route index element={<AdminDashboard />} />
              <Route path="utilisateurs">
                <Route index element={<AdminUsers />} />
                <Route path="show" element={<AdminUsersShow />} />
                <Route path="edit" element={<AdminUsersEdit />} />
              </Route>
              <Route path="settings">
                <Route index element={<AdminSettings />} />
                <Route path="show" element={<AdminSettingsShow />} />
                <Route path="edit" element={<AdminSettingsEdit />} />
              </Route>
              <Route path="otp_codes">
                <Route index element={<AdminOtpCodes />} />
                <Route path="show" element={<AdminOtpCodesShow/>} />
              </Route>
              <Route path="administrateurs">
                <Route index element={<AdminAdministrateurs />} />
                <Route path="show" element={<AdminAdministrateursShow />} />
                <Route path="edit" element={<AdminAdministrateursEdit />} />
              </Route>
              <Route path="subscriptions">
              <Route index element={<AdminSubscriptions />} />
              <Route path="show" element={<AdminSubscriptionsShow />} />
              <Route path="edit" element={<AdminSubscriptionsEdit />} />
            </Route>
              <Route path="payments">
                <Route index element={<AdminPayments />} />
                <Route path="show" element={<AdminPaymentsShow />} />
              </Route>
              <Route path="logs">
                <Route index element={<AdminLogs />} />
                <Route path="show" element={<AdminLogsShow />} />
              </Route>
              <Route path="supports">
                <Route index element={<AdminSupports />} />
                <Route path="show" element={<AdminSupportsShow />} />
                <Route
                  path="show/responses/show"
                  element={<AdminSupportsResShow />}
                />
                <Route
                  path="show/responses/new"
                  element={<AdminSupportResNew />}
                />
              </Route>
              <Route path="compagnies">
                <Route index element={<AdminCompanies />} />
                <Route path="show" element={<AdminCompaniesShow />} />
                <Route path="edit" element={<AdminCompaniesEdit />} />
              </Route>
              <Route path="activities">
                <Route index element={<AdminActivities />} />
                <Route path="show" element={<AdminActivitiesShow />} />
                <Route path="edit" element={<AdminActivitiesEdit />} />
                <Route path="new" element={<AdminActivitiesNew />} />
              </Route>
            </Route>
          </Route>
          <Route path="/auth" element={<AuthPage />}>
            <Route path="login" element={<LoginPage />} />
            <Route path="logout" element={<LoginPage />} />
          </Route>
          <Route path="*" element={<p>Erreur 404!</p>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
